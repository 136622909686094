import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import "./index.css";

let root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
// Use the root to render your app
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
