// FireBaseConfig.ts
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyBs97o-4WADIPi49mnMDm9TZV_RVRT21O0",
    authDomain: "shoppinglist-11d7f.firebaseapp.com",
    projectId: "shoppinglist-11d7f",
    storageBucket: "shoppinglist-11d7f.appspot.com",
    messagingSenderId: "957822737289",
    appId: "1:957822737289:web:a780e7a09616c40fcd9f58",
    measurementId: "G-K9KYPNQSQQ",
    databaseURL: "https://shoppinglist-11d7f-default-rtdb.europe-west1.firebasedatabase.app",
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const database = getDatabase(app);

export { app, firestore, database }; // Export the 'app', 'firestore', and 'database' named exports

