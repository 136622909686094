// App.tsx
import React from "react";
import "./App.css";
import Codes from "./components/codes";
import ListManager from "./components/ListManager"; // Import ListManager component

const App: React.FC = () => {
  return (
    <div className="lists">
      <ListManager /> {/* Add the ListManager component here */}
      <Codes word="hidden" />
      <Codes word="Work" />
      <Codes word="Mikkel" />
    </div>
  );
};

export default App;