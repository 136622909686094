// components/ListManager.tsx
import React, { useState, useEffect } from "react";
import { getDatabase, ref, onValue, push } from "firebase/database";
import { app } from "./FireBaseConfig";
import List from "./List";
import styles from "./ListManager.module.css";

type ListData = {
    id: string;
    name: string;
};

const ListManager: React.FC = () => {
    const [newListName, setNewListName] = useState("");
    const [lists, setLists] = useState<ListData[]>([]);
    const [isAddingList, setIsAddingList] = useState(false); // To control the pop-up

    const handleAddList = async (name: string) => {
        if (name.trim() === "") return;

        try {
            const db = getDatabase(app);
            const newListRef = ref(db, "lists");

            // Check if a list with the same name already exists
            const isNameTaken = lists.some((list) => list.name === name);
            if (isNameTaken) {
                alert("A list with the same name already exists.");
                return;
            }

            const newListData = {
                name: name,
            };

            // Push the new list to the database
            await push(newListRef, newListData);

            // Clear the input field and close the pop-up
            setNewListName("");
            setIsAddingList(false);

        } catch (error) {
            console.error("Error adding list:", error);
        }
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === "Enter") {
            event.preventDefault();
            // Call the button click event when Enter is pressed
            handleAddList(newListName);
        }
    };

    useEffect(() => {
        const db = getDatabase(app);
        const listsRef = ref(db, "lists");

        onValue(listsRef, (snapshot) => {
            const data = snapshot.val() || {};
            const listsData = Object.keys(data).map((key) => ({
                id: key,
                name: data[key].name, // Ensure the name property is set correctly
            }));
            setLists(listsData);
        });
    }, []);

    return (
        <div className={styles["list-manager"]}>
            {isAddingList ? (
                <div className={styles["list-popup"]}>
                    <input
                        type="text"
                        placeholder="Navn på liste"
                        value={newListName}
                        onChange={(e) => setNewListName(e.target.value)}
                        onKeyPress={handleKeyPress}
                        autoFocus={true}
                    />
                    <button onClick={() => handleAddList(newListName)}>Tilføj Liste</button>
                    <button onClick={() => setIsAddingList(false)}>Annuller</button>
                </div>
            ) : (
                <button
                    className={styles["add-button"]}
                    onClick={() => setIsAddingList(true)}
                >
                    +
                </button>
            )}

            {lists.map((list) => (
                <div key={list.id} className={styles["inner-list"]}>
                    <List name={list.name} />
                    <>
                        {console.log(list.name)}
                    </>
                </div>
            ))}
        </div>
    );
};

export default ListManager;
