import React from 'react';
import styled from 'styled-components';

// Define the styled components for the ItemBar
const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
`;

const ItemText = styled.div`
  flex-grow: 1;
  font-size: 16px;
  text-align: left;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #e74c3c;
`;

interface ItemBarProps {
    text: string;
    onRemove: () => void;
}

const ItemBar: React.FC<ItemBarProps> = ({ text, onRemove }) => {


    return (
        <ItemContainer>
            <ItemText>
                {text}
            </ItemText>
            <RemoveButton onClick={() => { onRemove(); }}>X</RemoveButton>
        </ItemContainer>
    );
};

export default ItemBar;
